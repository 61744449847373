.Appbar {
  text-align: left;
  background-color: #282c34;
  color: #ffffff;

}

.Appbar-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
