.Menubar {
  text-align: left;
  /*background-color: #282c34;*/
  color: #ffffff;

}

.Menubar-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.Menubar-link {
  color: #ffffff;
  margin: 10px;
}
