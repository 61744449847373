.Navbar {
  text-align: left;
  background-color: #282c34;
}

.Navbar-container {
  margin: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

  color: #ffffff;
  /*background-color: blue;*/
}

.Navbar-title {
  flex-grow: 1;
  text-align: center;
  /*background-color: green;*/
}

.Navbar-tagline {
  flex-grow: none;
  flex-shrink: none;
  /* background-color: blue; */
  padding: 5px;
}

.Navbar-version {
  flex-grow: none;
  padding: 5px;
  text-align: right;
  /*background-color: red;*/
}

.Navbar-profile {
  flex-grow: none;
  margin: 5px;
  /*background-color: yellow;*/
}
